<div class="currency-input" [ngClass]="{ error: errorMessage }">
  <span>$</span>
  <input
    type="text"
    (change)="onChange($event)"
    [value]="currencyValue"
    [min]="min"
    [max]="max"
    [attr.maxlength]="15"
    [disabled]="isDisabled"
    [ngStyle]="{ 'text-align': align }"
    placeholder="0.00"
    appCurrencyFormat
  />
</div>
<mat-error class="error-message" *ngIf="errorMessage">{{ errorMessage }}</mat-error>
