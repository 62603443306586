import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorSchemeService {
  private renderer: Renderer2;
  private colorScheme = 'color-scheme';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  load() {
    this.renderer.removeClass(document.body, this.colorScheme);
    this.renderer.addClass(document.body, this.colorScheme);
  }
}
