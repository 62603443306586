export enum AllotmentRequestStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  ReadyForBatch = 'ReadyforBatch',
  PendingApproval = 'PendingApproval',
  SubmittedAR = 'SubmittedAR',
}

export const AllotmentRequestStatusEnum = {
  ToDo: 'To Do',
  InProgress: 'In Progress',
  ReadyforBatch: 'Ready for Batch',
  PendingApproval: 'Pending SFA Approval',
  SubmittedAR: 'Submitted AR',
};
