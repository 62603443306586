import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatColumnDef, MatFooterRowDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounce, isBoolean, isNumber, isString, toLower } from 'lodash';
import { Observable, merge, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { PageableResult, PagingConfig } from '../../interface';
import { Pageable } from '../../type';
import { ListService } from './list.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, AfterContentInit, AfterViewInit {
  private _pageInit = false;
  private _pageable?: Pageable<any>;
  private _records: any[] = [];
  private _showPaginator = false;
  private _noRecordsFound = true;
  private _defaultSortBy = '';
  private _defaultSortDirection: 'asc' | 'desc';
  private _location: string;

  private readonly DEFAULT_PAGE_SIZE = 50;

  @Input() set pageIndex(value: number) {
    this.paging.pageIndex = value;
    this.renderPageIndex();
  }

  @Input() set pageSize(value: number) {
    this.paging.pageSize = value;
    this.renderPageSize();
  }

  @Input() set sortBy(value: string) {
    this.paging.sort = value;
    debounce(this.renderSort, 100).call(this);
  }

  @Input() set sortDirection(value: 'desc' | 'asc' | 'ASC' | 'DESC') {
    this.paging.direction = <'asc' | 'desc'>toLower(value);
    debounce(this.renderSort, 100).call(this);
  }

  @Input() set pageable(value: Pageable<any>) {
    this._pageable = value;
    // fire reload of pageable data when this changes
  }

  @Input()
  set showPaginator(value: any) {
    this._showPaginator = coerceBooleanProperty(value);
  }

  @Input() isScrollToTop: boolean = true;
  get showPaginator() {
    return this._showPaginator;
  }

  @Input() pageSizeOptions: number[] = [10, 20, 50, 100, 200];

  @Input() totalRecords = 0;

  @Input()
  set records(value: any[]) {
    this._records = value;
    if (this._pageInit || !this._pageable) {
      this.table.dataSource = value;
    }
  }
  get records() {
    return this._records;
  }
  get noRecordsFound() {
    return this._noRecordsFound;
  }

  get pageSizeKey() {
    return `pageSize.${this._location}`;
  }

  @Output() recordsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() totalRecordsChanged: EventEmitter<number> = new EventEmitter<number>();

  /**
   * params object will be passed to the pageable function
   */
  @Input() params: any;
  @Output() loading = true;
  @Output() loadingChanged = new EventEmitter<void>();

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  @ContentChildren(MatColumnDef) matColumnDefs!: QueryList<MatColumnDef>;
  @ContentChildren(MatSortHeader) matSortHeaders!: QueryList<MatSortHeader>;
  @ContentChild(MatHeaderRowDef, { static: true }) matHeaderRowDef!: MatHeaderRowDef;
  @ContentChild(MatRowDef, { static: true }) matRowDef!: MatRowDef<any>;
  @ContentChildren(MatFooterRowDef) matFooterRowDef!: QueryList<MatFooterRowDef>;

  // Must be subscribed to during the OnInit to get first paging
  @Output() pagingChanged: EventEmitter<{ pageIndex?: number; pageSize?: number }> = new EventEmitter<{
    pageIndex?: number;
    pageSize?: number;
  }>();
  @Output() paging: PagingConfig = {} as PagingConfig;

  // Must be subscribed during the OnInit to get first data change
  @Output() dataChange: EventEmitter<PageableResult<void>> = new EventEmitter<PageableResult<void>>();

  // Note: directives on the host component must be imported via constructor instead of ContentChild
  constructor(@Optional() private pSort: MatSort, private listService: ListService, private router: Router) {
    // remove UUIDs from location to match similar tables
    this._location = this.router.url
      .split('/')
      .filter(segment => !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(segment))
      .join('/');
  }

  ngOnInit() {
    if (this._pageable) {
      const localStoragePageSize = localStorage.getItem(this.pageSizeKey);
      if (localStoragePageSize) {
        this.paging.pageSize = +localStoragePageSize;
      } else {
        this.paging.pageSize = this.DEFAULT_PAGE_SIZE;
      }

      // Only automatically show the paginator when pageable is present and showPaginator hasn't been set.
      if (!isBoolean(this.showPaginator)) {
        this.showPaginator = true;
      }
      this._defaultSortBy = this.paging.sort;
      this._defaultSortDirection = this.paging.direction;
      this.initPageable();
    } else {
      // If not using pageable, loading is finished.
      this.loading = false;
      this.loadingChanged.emit();
    }
  }

  ngAfterContentInit() {
    // Override @ViewChild MatSort with imported MatSort so that interface piece reflects chosen sort
    this.sort = this.pSort;

    this.table.addHeaderRowDef(this.matHeaderRowDef);
    this.table.addRowDef(this.matRowDef);

    this.matFooterRowDef?.forEach(footerRow => {
      this.table.addFooterRowDef(footerRow);
    });

    this.matColumnDefs.forEach(columnDef => {
      this.table.addColumnDef(columnDef);
    });

    this._pageInit = true;

    // Update the interface paging
    this.updateInterfaceParams();
  }

  ngAfterViewInit() {
    let sortChange = new Observable();
    if (this.sort && this.paginator) {
      // When sort change occurs on interface, reset page index to 0.
      sortChange = this.sort.sortChange.pipe(
        map(() => {
          if (this.paginator) {
            this.paginator.pageIndex = 0;
          }
        }),
      );
    }

    let paginatorPage = new EventEmitter();
    if (this.paginator) {
      paginatorPage = this.paginator.page;
    }

    // When sort or paging change occurs on interface, copy paging values to paging object and emit pagingChanged
    // todo: sorting not initializing when component first loads
    merge(sortChange, paginatorPage)
      .pipe(
        startWith({}),
        map(() => {
          if (this.sort) {
            this.paging.sort = this.sort.active;
            this.paging.direction = this.sort.direction || 'asc';
          }
          if (this.paginator) {
            this.paging.pageIndex = this.paginator.pageIndex;
            this.paging.pageSize = this.paginator.pageSize;
          }
        }),
      )
      .subscribe(() =>
        this.pagingChanged.emit(
          this.paginator ? { pageIndex: this.paginator.pageIndex, pageSize: this.paginator?.pageSize } : {},
        ),
      );
  }

  add(obj: any) {
    this.records.push(obj);
    this.recordsChanged.emit(this.records);
    this.table.renderRows();
  }

  refresh(
    params?: any,
    keepPagingValue: boolean = false,
    forceSetTotalRecord = false,
  ): Observable<PageableResult<any>> {
    if (params) {
      this.params = params;
    }

    if (this.params?.filter) {
      Object.entries(this.params?.filter).forEach(([key, value]) => {
        if (!!value && !keepPagingValue) {
          this.pageIndex = 0;
        }
      });
    }

    if (!keepPagingValue) {
      // === Reset to default paging when executing another searching ===
      this.pageIndex = 0;
      const localStoragePageSize = localStorage.getItem(this.pageSizeKey);
      if (localStoragePageSize) {
        this.pageSize = +localStoragePageSize;
      }

      this.pagingChanged.emit(this.paging);
      // ===============================================================
    }

    return this.refreshTableData({ forceSetTotalRecord });
  }

  private renderSort() {
    // Set sort and direction settings if either interface version does not match the new paging
    // Update interface if: page is init, new sortBy and sortDirection are strings, new sortBy or sortDirection is different from active
    if (
      this._pageInit &&
      isString(this.paging.sort) &&
      isString(this.paging.direction) &&
      (this.paging.sort !== this.sort?.active || this.paging.direction !== this.sort?.direction)
    ) {
      this.sort?.sort({
        disableClear: true,
        id: this.paging.sort,
        start: <'asc' | 'desc'>this.paging.direction,
      });

      // todo: hack to update the interface, check https://github.com/angular/material2/issues/10242 for updates on fix
      const activeSortHeader = this.sort?.sortables.get(this.paging.sort) as MatSortHeader;
      if (activeSortHeader) {
        activeSortHeader['_setAnimationTransitionState']({ fromState: this.paging.direction, toState: 'active' });
      }
    }
  }

  private renderPageIndex() {
    // Update interface if: page is init, new pageIndex is number, new pageIndex is different from active pageIndex
    if (
      this.paginator &&
      this._pageInit &&
      isNumber(this.paging.pageIndex) &&
      this.paging.pageIndex !== this.paginator.pageIndex
    ) {
      this.paginator.pageIndex = this.paging.pageIndex;
    }
  }

  private renderPageSize() {
    // Update interface if: page is init, new pageSize is number, new pageSize is different from active pageSize
    if (
      this.paginator &&
      this._pageInit &&
      isNumber(this.paging.pageSize) &&
      this.paging.pageSize !== this.paginator?.pageSize
    ) {
      this.paginator.pageSize = this.paging.pageSize;
    }
  }

  private updateInterfaceParams() {
    this.renderSort();
    this.renderPageIndex();
    this.renderPageSize();
  }

  private initPageable() {
    if (!this._pageable) {
      throw new Error('Unable to set up data loading via Pageable. Pageable function not found.');
    }

    this.pagingChanged
      .pipe(
        switchMap(() => {
          localStorage.setItem(this.pageSizeKey, this.paging.pageSize.toString());
          //scroll to top if pageIndex change
          if ((this.paginator?.hasNextPage() || this.paginator?.hasPreviousPage()) && this.isScrollToTop) {
            this.listService.scrollToTop();
          }

          return this.refreshTableData();
        }),
      )
      .subscribe();
  }

  private refreshTableData(options?: { forceSetTotalRecord?: boolean }): Observable<PageableResult<any>> {
    if (this._pageable) {
      this.loading = true;
      this.loadingChanged.emit();

      if (this.params?.search && this.params?.search.toString() !== localStorage.getItem('search')?.toString()) {
        localStorage.setItem('search', this.params.search);
        this.paging.pageIndex = 0;
        this.renderPageIndex();
      }

      if (!this.params?.search && localStorage.getItem('search')) {
        localStorage.removeItem('search');
        this.paging.pageIndex = 0;
        this.renderPageIndex();
      }

      return this._pageable(this.paging, this.params).pipe(
        catchError(err => {
          console.error('Error when calling list pageable.', err);

          // Finish loading data.
          this.loading = false;
          this.loadingChanged.emit();

          // On error, return the existing records so no changes happen to the active list.
          return of({ records: this.records, total: this.totalRecords });
        }),
        tap((result: PageableResult<any>) => {
          this.records = result.records;
          this.totalRecords = result.total;
          // Emit messages for changes
          this.recordsChanged.emit(this.records);
          this.dataChange.emit(result);
          this.totalRecordsChanged.emit(result.total);

          this._noRecordsFound = result.records.length === 0;

          // Render table rows to show new records.
          this.table.renderRows();

          // Finish loading data.
          this.loading = false;
          this.loadingChanged.emit();
        }),
      );
    } else {
      const result = {
        total: options?.forceSetTotalRecord ? this.totalRecords : this.records.length,
        records: this.records,
      };

      this.totalRecords = result.total;

      // Emit messages for changes
      this.recordsChanged.emit(result.records);
      this.dataChange.emit(result);
      this.totalRecordsChanged.emit(result.total);

      // Render table rows to show new records.
      this.table.renderRows();

      return of(result);
    }
  }
}
