import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogType } from 'app/shared/enum';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  confirmType: ConfirmDialogType;
  title: string;
  confirm: string;
  description: string;
  subDescription: string | null;
  cancelButton?: string = 'Cancel';
  confirmButton?: string | null;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type?: ConfirmDialogType;
      title: string;
      confirm: string;
      description: string;
      subDescription?: string;
      cancelButton?: string;
      confirmButton?: string;
    },
  ) {}

  ngOnInit(): void {
    this.confirmType = this.data.type ? this.data.type : ConfirmDialogType.UPDATE;
    this.title = this.data.title;
    this.confirm = this.data.confirm;
    this.description = this.data.description;
    this.subDescription = this.data.subDescription ? this.data.subDescription : null;
    this.cancelButton = this.data.cancelButton ? this.data.cancelButton : 'Cancel';
    this.confirmButton = this.data.confirmButton
      ? this.data.confirmButton
      : this.data.type === 'UPDATE'
      ? 'Yes'
      : 'Delete';
  }

  close(): void {
    this.dialogRef.close({ confirmed: false });
  }

  agree(): void {
    this.dialogRef.close({ confirmed: true });
  }
}
