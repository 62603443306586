import { DesignContractPacketStatus } from './design-contract-packet-status.model';

export interface DesignContractPacket {
  id: string;
  constructionBudget: string;
  estimatedConstructionCost: string;
  finalDesignDate: string | null;
  scopeOfWork: string | null;
  summary: string[];
  businessEntityType: null;
  pc: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  } | null;
  consultantState: BillingState | null;
  federalFundingSources: string;
  isNifOther: boolean;
  nifOther: string;
  psProjectDescription: string;
  psProjectNumber: string;
  psSchool: string;
  stateFundingSources: string;
  contractDesignPacketStatus: DesignContractPacketStatus;
  previousContractDesignPacketStatus: DesignContractPacketStatus;
  contractDesign: ContractDesign;
  contractDesignPacketAgDoc: ContractDesignPacketAgDoc;
  contractDesignPacketToPhases: ContractDesignPacketToPhase[];
  contractDesignPacketPcCheckListGroups: ContractDesignPacketPCCheckListGroup[];
  contractDesignPacketAttachments: ContractDesignPacketAttachment[];
}

export interface ContractDesignPacketAttachment {
  id?: string;
  documentName?: string;
  fileName: string;
  url: string;
  updateUrl: string;
}

export interface DesignContractPacketContents {
  statuses: { value: string; displayLabel: string }[];
  vendors: { value: string; displayLabel: string }[];
  consultants: Consultant[];
  businessEntityTypes: { value: string; displayLabel: string }[];
  states: { id: string; name: string; stateAbbreviation: string }[];
  contractDesignPacketStatuses: ContractDesignPacketStatus[];
  designContractPacketDocumentFields: Array<{ value: string; displayLabel: string }[]>;
  csvFields: { value: string; displayLabel: string }[];
  pcUsers: { id: string; firstName: string; lastName: string; phoneNumber: string; email: string }[];
  businessTypes: { value: string; displayLabel: string }[];
}

export interface DesignContractPacketDocumentFields {
  designContractPacketDocumentFields: Array<{ value: string; displayLabel: string }[]>;
}

interface ContractDesignPacketStatus {
  id: string;
  name: string;
  backGroundColor: string;
  textColor: string;
  isAutoBySystem: boolean;
  nextStatus: PacketStatus | null;
  revertStatus: PacketStatus | null;
}

interface PacketStatus {
  name: string;
  backGroundColor: string;
  textColor: string;
  isAutoBySystem: boolean;
}

interface ContractDesign {
  id: string;
  contract: ContractInfo;
}

interface ContractInfo {
  id: string;
  contractNo: string;
  jobName: string;
  jobNumber: string;
  locationName: string;
  vendor: Vendor | null;
}

interface Vendor {
  id: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingZip: string;
  fein: string;
  geLicense: string;
  vendorName: string;
  billingState: BillingState;
  vendorContact: VendorContact;
  businessType: BusinessType | null;
}

interface BusinessType {
  id: string;
  businessType: string;
}

interface BillingState {
  id: string;
  name: string;
  stateAbbreviation: string;
}

interface VendorContact {
  id: string;
  vendorContactEmail: string;
  vendorContactFirstName: string;
  vendorContactLastName: string;
  vendorContactPhone: string;
}

export interface VendorContactConsultant {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  createdByUserId: string | null;
  updatedByUserId: string | null;
  isDeleted: boolean;
  vendorId: string;
  vendorContactFirstName: string;
  vendorContactLastName: string;
  vendorContactPhone: string;
  vendorContactEmail: string;
  isPrimary: boolean;
}

export interface Consultant {
  value: string;
  displayLabel: string;
  vendorContacts: VendorContactConsultant;
  billingCity: string;
  billingState: BillingState | null;
  billingAddress1: string;
  billingAddress2: string;
  billingZip: string;
  businessType: BusinessType | null;
}

interface ContractDesignPacketAgDoc {
  id: string;
  scopeOfWork: string | null;
  specialConditions: string;
  contractDesignPacketAgDocProServiceItems: ContractDesignPacketAgDocProServiceItem[];
  reimbursables: string;
}

export interface ContractDesignPacketAgDocProServiceItem {
  id: string;
  contractDesignPacketAgDocProService: ContractDesignPacketAgDocProService;
  isCheck: boolean;
  requiredInput: string | null;
}

interface ContractDesignPacketAgDocProService {
  id: string;
  inputName: null | string;
  isRequiredInput: boolean;
  name: string;
  order: number;
}

export interface ContractDesignPacketToPhase {
  id: string;
  isCheck: boolean;
  isIncludeDelivarables: boolean;
  isIncludeTask: boolean;
  phaseAmount: string;
  phaseDurationByDays: string;
  contractDesignPacketPhase: { id: string; name: string };
  contractDesignPacketPhaseDeliverables: ContractDesignPacketPhaseRootStatement[];
  contractDesignPacketPhaseTasks: ContractDesignPacketPhaseRootStatement[];
}

export interface ContractDesignPacketPhaseRootStatement extends ContractDesignPacketPhaseStatement {
  childContractDesignPacketPhaseTasks: ContractDesignPacketPhaseStatement[];
}

export interface ContractDesignPacketPhaseStatement {
  id?: string;
  name: string;
  order?: number;
  isCheck: boolean;
}

export interface DesignContractPacketRequestBody {
  pc?: { id?: string | null; phoneNumber?: string | null } | null;
  vendor?: {
    id?: string | null;
    billingAddress1?: string | null;
    billingAddress2?: string | null;
    billingCity?: string | null;
    billingZip?: string | null;
    statesId?: string | null;
    vendorContact?: {
      id?: string | null;
      vendorContactFirstName?: string | null;
      vendorContactLastName?: string | null;
      vendorContactPhone?: string | null;
      vendorContactEmail?: string | null;
    } | null;
    businessTypeId?: string | null;
    fein?: string | null;
    geLicense?: string | null;
  };
  contractDesign: { id: string };
  contractDesignPacketStatusId: string;
  estimatedConstructionCost?: number | null;
  constructionBudget?: number | null;
  finalDesignDate?: string | null;
  scopeOfWork?: string | null;
  summary?: string[] | null;
  contractDesignPacketAgDoc?: {
    scopeOfWork: string | null;
    specialConditions?: string | null;
    reimbursables?: number | null;
    contractDesignPacketAgDocProServiceItems: {
      id: string;
      isCheck: boolean;
      requiredInput: string | null;
    }[];
  };
  contractDesignPacketToPhases?: ContractDesignPacketToPhase[] | null;
  pcCheckListItemToContractDesignPackets?: { id: string; isCheck: boolean }[];
  contractDesignPacketAttachments?: ContractDesignPacketAttachment[] | null;
  consultantStateId?: string | null;
  psProjectNumber?: string | null;
  psSchool?: string | null;
  psProjectDescription?: string | null;
  isNifOther?: boolean;
  nifOther?: string | null;
  stateFundingSources?: string | null;
  federalFundingSources?: string | null;
}

export interface ContractDesignPacketPCCheckListGroup {
  id: string;
  name: string;
  isRadioGroup: boolean;
  isRow: boolean;
  showHeader: boolean;
  contractDesignPacketPcCheckListItems: ContractDesignPacketPCCheckListItem[];
  childContractDesignPacketPcCheckListGroups: Omit<
    ContractDesignPacketPCCheckListGroup,
    'childContractDesignPacketPcCheckListGroups'
  >[];
}

export interface ContractDesignPacketPCCheckListItem {
  id: string;
  name: string;
  contractDesignPacketPcCheckListItemToContractDesign: { id: string; isCheck: boolean };
}

export interface ShareDesignContractPacketRequestBody {
  assigneeId: string;
  comment: string;
  sections: string[];
}
