import { UserResponse } from '@services/user.service';
import { Milestone } from './milestone.model';

interface Activity extends Omit<Milestone, 'date'> {
  date: Date | null;
  isEditing?: boolean;
}

export interface PublishJobBaselinePayload {
  activities: Activity[];
  reason?: string;
}

export interface JobBaseline {
  id: string;
  createdAt: Date;
  reason: string;
  version: string;
  createdByUser: UserResponse;
}
